import React, { useState, useEffect } from "react"
import cx from "classnames"

import CONSTANTS from "@/constants/index"
import styles from "@/components/core/SelectDropdown/index.module.scss"

const SelectDropdown = props => {
  const [showList, setShowList] = useState(false)
  const [opened, setOpened] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  let isFocused = false
  const dropDownClass = cx({
    "select-dropdown": true,
    "dropdown--open": showList,
  })

  useEffect(() => {
    if (showList) {
      setOpened(true)
    } else if (!showList && opened && props.onClose) {
      props.onClose()
      setOpened(false)
    }
    const targetEelement = e => {
      if (
        showList &&
        document.getElementById(props.id) &&
        !document.getElementById(props.id).contains(e.target)
      ) {
        setShowList(false)
      }
    }
    document.addEventListener("click", targetEelement)
    return () => {
      document.removeEventListener("click", targetEelement)
    }
  }, [showList])

  useEffect(() => {
    setFilteredData(props.data)
  }, [props.data])

  const handleSelect = value => {
    props.onChange(value)
    setShowList(false)
  }

  const handleMouseDownEvent = () => {
    isFocused = true
    setShowList(!showList)
  }

  const handleBlurEvent = e => {
    if (e instanceof MouseEvent) {
      isFocused
      setShowList(false)
    }
    if (typeof props.onBlur === "function") {
      props.onBlur()
    }
  }

  const toggleList = () => {
    if (showList) {
      setShowList(false)
      document.getElementById(props.id).focus()
    } else {
      setShowList(true)
      document.getElementById(`${props.id}-list`)?.childNodes[0].focus()
    }
  }

  const btnKeyDown = e => {
    const { KEYCODE } = CONSTANTS
    switch (e.keyCode) {
      case KEYCODE.SPACE:
        break
      case KEYCODE.ENTER:
        e.preventDefault()
        toggleList()
        break
      default:
        break
    }
  }

  const handleKeyDown = (i, e) => {
    const { KEYCODE } = CONSTANTS
    const ele = document.getElementById(`${props.id}-list`).childNodes
    switch (e.keyCode) {
      case KEYCODE.ESC:
        toggleList()
        break
      case KEYCODE.UP:
        if (i > 0) ele[i - 1].focus()
        break
      case KEYCODE.DOWN:
        if (i < ele.length - 1) ele[i + 1].focus()
        break
      case KEYCODE.SPACE:
        break
      case KEYCODE.ENTER:
        ele[i].click()
        document.getElementById(props.id).focus()
        break
      default:
        break
    }
  }
  const {
    customClass,
    id,
    tabIndex,
    label,
    ariaLabel,
    ariaRequired,
    value,
    ariaStateText,
    data,
    showError,
    errorMessage,
  } = props

  const filterCityNames = e => {
    const key = e?.key?.toLowerCase()
    if (key?.length === 1 && key?.match(CONSTANTS.REGEX_CONTAINS_LETTER)) {
      const filtered = data?.filter(item => item?.toLowerCase().startsWith(key))
      setFilteredData(filtered ?? data)
    } else if (key === "backspace") {
      setFilteredData(data)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        onClick={handleMouseDownEvent}
        onBlur={handleBlurEvent}
        className={`${dropDownClass} ${customClass ?? ""}`}
        onKeyDown={e => filterCityNames(e)}
      >
        <div
          id={id}
          className={`select-dropdown__field ${
            showError ? "--with-error" : ""
          }`}
          onKeyDown={btnKeyDown}
          tabIndex={tabIndex ?? 0}
          role="combobox"
          aria-controls="dropdown-listbox"
          aria-expanded={showList}
          aria-haspopup="true"
          aria-label={
            label
              ? label.replace(/\*/g, "")
              : ariaLabel
              ? ariaLabel.replace(/\*/g, "")
              : ""
          }
          aria-required={ariaRequired ?? label.indexOf("*") > 0 ? true : false}
          aria-describedby={`${id}-selected`}
        >
          <div className="select-dropdown__display">
            <span
              aria-hidden
              className={`select-dropdown__placeholder ${
                value ? "--label" : ""
              }`}
            >
              {label}
            </span>
            {value && (
              <span id={`${id}-selected`} className="select-dropdown__value">
                {value}
              </span>
            )}
          </div>
          <div
            className={`select-dropdown__icon ${showList ? "--open" : ""}`}
            aria-hidden="true"
          >
            <img src="/icons/down-icon.svg" alt="open-icon" />
          </div>
        </div>
        <ul
          id={`${id}-list`}
          className="select-dropdown__dropdown"
          role="listbox"
          tabIndex="-1"
          aria-expanded="true"
          data-testid={"select-dropdown"}
        >
          {!ariaStateText &&
            filteredData?.map((item, i) => (
              <li
                key={i}
                className={`select-dropdown__list-item ${
                  item === value && "selected-item"
                }`}
                aria-selected={item === value}
                role="option"
                tabIndex="-1"
                onClick={() => handleSelect(item)}
                onKeyDown={e => handleKeyDown(i, e)}
              >
                <span className="select-dropdown__list-value">{item}</span>
              </li>
            ))}
          {ariaStateText &&
            filteredData?.map((item, i) => (
              <li
                key={i}
                className={`select-dropdown__list-item ${
                  item.code === value && "selected-item"
                }`}
                aria-selected={item === value}
                role="option"
                tabIndex="-1"
                aria-label={item.name}
                onClick={() =>
                  handleSelect(props?.showStateName ? item?.name : item?.code)
                }
                onKeyDown={e => handleKeyDown(i, e)}
              >
                <span className="select-dropdown__list-value">
                  {props?.showStateName ? item?.name : item?.code}
                </span>
              </li>
            ))}
        </ul>
        {showError && errorMessage && (
          <span
            aria-live="polite"
            role="region"
            className="select-dropdown__error-message"
          >
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

SelectDropdown.defaultProps = {
  label: "",
}

export default SelectDropdown
